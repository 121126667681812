/*
  These have been copied directly from node modules because importing from there is prevented.
  Use caution when upgrading packages.
*/
@import './lib_styles/react-grid-layout/styles.css';
@import './lib_styles/react-resizable/styles.css';
@import './lib_styles/react-toastify/ReactToastify.css';
@import './lib_styles/primereact/theme.css';
@import './lib_styles/primereact/primereact.min.css';
@import './lib_styles/primeicons/primeicons.css';


@font-face {
  font-family: Roboto-Bold;
  src: url(./fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: TitilliumWeb-Black;
  src: url(./fonts/TitilliumWeb-Black.ttf);
}

@font-face {
  font-family: TitilliumWeb-Bold;
  src: url(./fonts/TitilliumWeb-Bold.ttf);
}

@font-face {
  font-family: TitilliumWeb-BoldItalic;
  src: url(./fonts/TitilliumWeb-BoldItalic.ttf);
}

@font-face {
  font-family: TitilliumWeb-ExtraLight;
  src: url(./fonts/TitilliumWeb-ExtraLight.ttf);
}

@font-face {
  font-family: TitilliumWeb-ExtraLightItalic;
  src: url(./fonts/TitilliumWeb-ExtraLightItalic.ttf);
}

@font-face {
  font-family: TitilliumWeb-Italic;
  src: url(./fonts/TitilliumWeb-Italic.ttf);
}

@font-face {
  font-family: TitilliumWeb-Light;
  src: url(./fonts/TitilliumWeb-Light.ttf);
}

@font-face {
  font-family: TitilliumWeb-LightItalic;
  src: url(./fonts/TitilliumWeb-LightItalic.ttf);
}

@font-face {
  font-family: TitilliumWeb-Regular;
  src: url(./fonts/TitilliumWeb-Regular.ttf);
}

@font-face {
  font-family: TitilliumWeb-SemiBold;
  src: url(./fonts/TitilliumWeb-SemiBold.ttf);
}

@font-face {
  font-family: TitilliumWeb-SemiBoldItalic;
  src: url(./fonts/TitilliumWeb-SemiBoldItalic.ttf);
}

html {
  font-size: 12px;
}

.p-paginator {
  background-color: transparent !important;
}

.p-paginator-element.p-link {
  color: #ffffff !important;
}

.p-chips, .p-chips-multiple-container {
  color: #ffffff !important;
  background-color: transparent !important;
  border: 0 !important;
}

.p-paginator-element.p-highlight {
  color: #000000 !important;
}

.font-titillium-web-extra-light {
  font-family: TitilliumWeb-ExtraLight;
}

.font-roboto-bold {
  font-family: Roboto-Bold;
}

.font-titillium-web {
  font-family: TitilliumWeb-Regular;
}

.font-titillium-web-light {
  font-family: TitilliumWeb-Light;
}

.font-titillium-web-semi-bold {
  font-family: TitilliumWeb-SemiBold;
}

.hover-background-green:hover {
  background-color: #00f600 !important;
}

.hover-background-grey:hover {
  background-color: #e8e8e8 !important;
  border-radius: 4px;
}

.hover-green-border:hover,
.hover-green-border:hover > p {
  border-color: #00f600 !important;
}

.hover-green-text:hover,
.hover-green-text:hover > p {
  color: #00f600 !important;
}

.hover-underline:hover {
  text-decoration: underline !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.date-picker {
  display: flex;
  text-align: center;
  align-self: center;
}

.react-datepicker__close-icon {
  margin-top: 2px;
}

.react-datepicker-wrapper {
  margin: auto;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
  padding: 0;
}

.settings-datepicker-container .MuiInputBase-input {
  font-family: TitilliumWeb-Regular !important;
  color: #ffffff !important;
}

p {
  font-family: TitilliumWeb-Regular;
  color: #ffffff;
}

a {
  text-decoration: none !important;
}
a:-webkit-any-link {
  text-decoration: none !important;
}

input:-webkit-autofill {
  background-color: #ffffff !important;
}

.vehicle-details-nav-item-hover:hover {
  box-shadow: inset 0px -5px 0px 0px #0095da;
  opacity: 1 !important;
}

.vehicle-details-nav-item-active {
  box-shadow: inset 0px -5px 0px 0px #0095da;
  opacity: 1 !important;
}

.react-datepicker__time-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0;
}

.react-datepicker__time-list-item {
  font-size: 10px;
  font-family: TitilliumWeb-Regular;
}

.react-datepicker__day {
  font-size: 10px;
  font-family: TitilliumWeb-Regular;
}

html {
  background-color: #000;
}
html.settings-bg {
  background: #0095da;
}
body:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index:-1;
  background: url(/img/login-background.jpg) center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  pointer-events: none;
  /* fix for mobile browser address bar appearing disappearing */
  height: 100vh;
}
.settings-bg body:after {
  display: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

.opacity-on-hover:hover {
  opacity: 1 !important;
}

.opacity-and-border-on-hover:hover {
  opacity: 1 !important;
  border: 1px solid white !important;
}

.date-picker .MuiInputBase-input {
  color: white;
}

.white-placeholder::placeholder {
  color: white;
}

.MuiInput-underline:before {
  transition: unset !important;
  border-bottom: unset !important;
}

.MuiInputBase-input {
  font-size: 16px !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #0095da !important;
}

.MuiPickersDay-daySelected {
  background-color: #0095da !important;
}

.MuiPickersDay-current {
  border: 1px solid #0095da !important;
  background-color: transparent;
}

p.MuiTypography-root {
  color: black;
}

.bars .p-multiselect-label-container {
  display: none;
}

.bars .p-multiselect-trigger {
  width: 40px !important;
  height: 40px !important;
  background-image: url('/img/icon_columns.svg') !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.bars.p-multiselect {
  background: none;
  border: none;
}

.p-button.p-button-danger:enabled:focus{
  box-shadow: 0 0 0 0.2rem #83b9ff80;
}

.p-button, .p-button-icon, .p-button-text, .p-button.p-button-danger.p-button-outlined,
.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-button.p-button-danger.p-button-outlined:enabled:focus,
.p-button.p-button-danger.p-button-outlined:enabled:hover {
  color:#ffffff;
  border-color: #ffffff;
}

.datepicker-container-white-bg {
  margin-top: 8px;
  margin-bottom: 8px;
}

.datepicker-container-white-bg input, .datepicker-container-white-bg div {
  background-color: rgb(235, 235, 235) !important;
  font-size: 14px !important;
  text-align: center !important;
  color: black !important;
  border-radius: 100px !important;
  height: 28px !important;
  width: 100% !important;
}

.white-placeholder-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.7);
  opacity: 1; /* Firefox */
}

.white-placeholder-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.7);
}

.white-placeholder-input::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.7);
}

input:disabled {
  opacity: 0.7 !important;
}

#checkout-button {
  height: 50px;
  width: 100%;
  border: 2px solid rgb(0, 149, 218);
  color: rgb(0, 149, 218);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
}

#checkout-button:disabled {
  opacity: 0.5;
  cursor: auto;
}

.checkmark {
  position: absolute; /* Positions the checkmark relative to the container */
  top: 10px; /* Adjust for spacing */
  right: 10px; /* Adjust for spacing */
  width: 30px; /* Size of the green circle */
  height: 30px; /* Size of the green circle */
  background-color: rgb(14, 163, 14); /* Green background */
  border-radius: 8px;
  display: flex; /* Centers the checkmark icon */
  align-items: center;
  justify-content: center;
  color: white; /* Checkmark color */
  font-size: 18px; /* Checkmark size */
  font-weight: bold; /* Optional bold for checkmark */
  border: 2px solid white; /* White border */
  line-height: 30px;
}

.checkmark::before {
  content: '✔'; /* Unicode checkmark */
}
